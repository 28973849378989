@tailwind base;
@tailwind components;
@tailwind utilities;
/* Import Google font - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    word-wrap: break-word;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #000000;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.load-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #fdba2c;
}

.bar {
    content: "";
    display: inline;
    position: absolute;
    width: 0;
    height: 100%;
    left: 50%;
    text-align: center;
}

.bar:nth-child(1) {
    background-color: #da4733;
    animation: loading 3s linear infinite;
}

.bar:nth-child(2) {
    background-color: #3b78e7;
    animation: loading 3s linear 1s infinite;
}

.bar:nth-child(3) {
    background-color: #fdba2c;
    animation: loading 3s linear 2s infinite;
}

@keyframes loading {
    from {
        left: 50%;
        width: 0;
        z-index: 100;
    }

    33.3333% {
        left: 0;
        width: 100%;
        z-index: 10;
    }

    to {
        left: 0;
        width: 100%;
    }
}

.custom-h {
    height: auto !important;
}

.scroll {
    overflow: auto;
}

.scroll:hover {
    color: #717171;
}

.scrollHome {
    overflow: auto;
    color: #717171;
}

.scroll::-webkit-scrollbar, .scrollHome::-webkit-scrollbar {
    /* appearance: none; */
    width: 10px;
    height: 0px;
}

.scroll::-webkit-scrollbar-thumb, .scrollHome::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 20px;
    border-radius: 10px;
}

@media screen and (min-width: 1024px) {
    .custom-h {
        height: 100vh !important;
    }
}

::selection {
    color: #fff;
    background: #6990F2;
}

.wrapper {
    width: 400px;
    max-width: 100%;
    /* background: #fff; */
    border-radius: 5px;
    /* padding: 30px; */
    box-shadow: 7px 7px 12px rgba(0, 0, 0, 0.05);
}

.wrapper .form {
    height: 215px;
    display: flex;
    cursor: pointer;
    margin: 30px 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    border: 2px dashed #6990F2;
}

.wrapper .form img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.form :where(i, p) {
    color: #6990F2;
}

.form i {
    font-size: 30px;
}

.form p {
    margin-top: 15px;
    font-size: 14px;
}

section .row {
    margin-bottom: 10px;
    background: #E9F0FF;
    list-style: none;
    padding: 15px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

section .row i {
    color: #6990F2;
    font-size: 30px;
}

section .details span {
    font-size: 14px;
}

.progress-area .row .content {
    width: 100%;
    margin: 0 20px;
}

.progress-area .details {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    justify-content: space-between;
}

.progress-area .content .progress-bar {
    display: flex;
    height: 6px;
    width: 100%;
    margin-bottom: 4px;
    background: #fff;
    border-radius: 30px;
}

.content .progress-bar .progress {
    height: 100%;
    width: 0%;
    background: #6990F2;
    border-radius: inherit;
}

.uploaded-area {
    max-height: 232px;
    overflow-y: scroll;
}

.uploaded-area.onprogress {
    max-height: 150px;
}

.uploaded-area::-webkit-scrollbar {
    width: 0px;
}

.uploaded-area .row .content {
    display: flex;
    align-items: center;
}

.uploaded-area .row .details {
    display: flex;
    margin-left: 15px;
    flex-direction: column;
}

.uploaded-area .row .details .size {
    color: #404040;
    font-size: 11px;
}

.progress-area li .fa-check {
    font-size: 16px;
}



.form {
    width: 400px;
    max-width: 100%;
    margin-top: 2rem;
}

.file-input {
    width: 100%;
    opacity: 0;
    height: 100%;
    position: absolute;
}

.output {
    height: 215px !important;
}

a {
    color: #5469d4;
    text-decoration: unset;
}

.center-center {
    align-items: center;
    justify-content: center;
}

.padding-top--64 {
    padding-top: 64px;
}

.padding-top--24 {
    padding-top: 24px;
}

.padding-top--48 {
    padding-top: 48px;
}

.padding-bottom--24 {
    padding-bottom: 24px;
}

.padding-horizontal--48 {
    padding: 48px;
}

.padding-bottom--15 {
    padding-bottom: 15px;
}


.formbg {
    margin: 0px auto;
    width: 100%;
    max-width: 448px;
    background: white;
    border-radius: 4px;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}

label, h3 {
    margin-bottom: 10px;
    color: white;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    display: block;
}

.grid--50-50 {
    display: grid;
    grid-template-columns: 50% 50%;
    /* align-items: center; */
}

.grid--80-20 {
    display: grid;
    grid-template-columns: 70% 30%;
    /* align-items: center; */
}

.w-80 {
    width: 80%;
}

.field input {
    font-size: 16px;
    line-height: 28px;
    padding: 8px 16px;
    width: 100%;
    min-height: 44px;
    border: unset;
    border-radius: 4px;
    outline-color: rgb(84 105 212 / 0.5);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}

input[type="submit"] {
    background-color: rgb(84, 105, 212);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
        rgb(84, 105, 212) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}

.error {
    color: #da4733;
    font-size: 12px;
    margin-left: 8px;
}

.dropdown {
    font-size: 14px;
    line-height: 28px;
    width: 100%;
    margin-top: 5px;
    border: unset;
    border-radius: 4px;
    background-color: rgb(9, 9, 9);
    box-shadow: rgba(60, 66, 87, 0.16) 0px 0px 0px 1px;
}

.dropdown-item, .selected-cat-container {
    display: flex;
    color: rgb(255, 255, 255);
    padding: 8px 5px;
    cursor: pointer;
    transition: all .25s;
}

.dropdown-img-container, .selected-img-container {
    display: flex;
    align-items: center;
    width: 90%;
    transition: all .25s;
}

.dropdown-img-container img, .selected-img-container img {
    width: 30px;
    height: 100%;
    border-radius: 50%;
    margin-left: 15px;
}

.dropdown-img-container span, .selected-img-container span {
    color: rgb(255, 255, 255);
    font-size: 14px;
    margin-left: 10px;
}

.dropdown-img-container:hover {
    transform: scale(1.05);
}

.dropdown-item .dropdown-icon, .selected-icon {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    color: white;
}

.dropdown-item:hover {
    background-color: rgb(0 0 0);
    box-shadow: rgba(60, 66, 87, 0.16) 0px 0px 0px 2px;
}


.selected-icon:hover {
    color: #da4733;
    cursor: pointer;
    transition: all .25s;
}


.selected-cat-div {
    font-size: 16px;
    line-height: 28px;
    width: 100%;
    margin-top: 5px;
    border: unset;
    border-radius: 4px;
    outline-color: rgb(84 105 212 / 0.5);
    padding: 0;
}

.selected-cat {
    color: #ffff;
    width: 370px;
    max-width: 90%;
    padding: 5px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selected-cat+span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    font-size: 26px;
    padding: 5px;
    color: #ffff;
    cursor: pointer;
    transition: all .25s;
}

.selected-cat-container {
    font-size: 16px;
    width: 100%;
    margin-top: 5px;
    border-radius: 4px;
    background-color: rgb(9, 9, 9);
    box-shadow: rgba(60, 66, 87, 0.16) 0px 0px 0px 1px;
}

.slick-dots li button:before {
    color: #5469d4 !important;
}
.slick-dots {
    bottom: -5px !important;
}

.circle_spinner {
    /* font-size: 16px; */
    animation: spin 1s infinite linear;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: spin;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }